import SCSVG from "/assets/logo/ScLogoSvg.svg";
import Background from "/assets/images/scback.png";
import { useRouter } from "next/router";
import { Paper, Typography, Box, Button, Stack } from "@mui/material";

const stylesBackground = {
  paperContainer: {
    height: "100%",
    backgroundColor: "inherit",
    backgroundImage: `url(${Background.src})`,
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
  },
};

const Custom404 = () => {
  const router = useRouter();
  const goBack = () => {
    router.push("/dashboard");
  };

  return (
    <Paper
      sx={stylesBackground.paperContainer}
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          pt: 5,
          pb: 5,
          pr: 10,
          pl: 10,
          //   marginBottom: "200px",
          width: "100vw",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack alignItems="center" width="400px" mb={2} >
          <Box width="200px"><SCSVG alt="SCSVG" /></Box>
          <div className="threed-text">Uh Oh..</div>
          <Typography>
            The page you are looking for might have been removed, had its name
            changed, or is temporarily unavailable.
          </Typography>
        </Stack>

        <Button
          className="tailwind-button"
          onClick={goBack}
          sx={{ pb: 1, color: "black" }}
        >
          Go Back
        </Button>
      </Paper>
    </Paper>
  );
};

export default Custom404;
